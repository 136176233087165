
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import museum from '../../img/museum.svg'
import React from 'react';
import ReactDOM from 'react-dom';
import { useMemo, useState } from "react";
import BankConfigModal from './BankConfigModal'

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { API_URL } from "../../constant/API_Settings";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Loader from '../operational-model/Employee/Loader.component'
import PaginationComponent from '../layouts/PaginationComponent';

import { convertAmountToWords,preventNonNumericalInput,prevNonNumericalWithDec } from '../layouts/Sidebar_Progress.component'
var BankModal = (props) => ReactDOM.createPortal(
  <BankConfigModal  {...props} />, document.body
);
const  PaginationComp  = (props) => (
  < PaginationComponent  {...props} />
     );
const BankOpeningBalance = (location) => {
  const backPage = useMemo(() => {

    if (location?.location?.backpage) {
      return location?.location?.backpage
    }
    else
      return undefined
  }, [location?.location?.pathname])

  const [double, setDouble] = useState(true);
  const [stat_operation, setStartoperation] = useState();
  const UseModal = () => {

    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
     
      var isdayclose = checkoption.isdayclose;
      if (isdayclose == 1) {
        swal("", "You do not have this permission after day close", false ? "success" : "warning")
      }
      else {
        if (isShowing) {
          setInputValues({})
          setSelected({})
          setSelectedOptions([])
          setErrors({})
          setSuccess({})
          setDropdownData([])
        }
        if (!isShowing) {
          setDouble(true);
          getDigitalPayment();
        }
        setIsShowing(!isShowing);
      }

    }

    return {
      isShowing,
      toggle,
    }
  };
  const [permission, setPermission] = useState([])
  const [delete_id, deleteId] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  function setBank() {

    if (check == false) {
      swal("Please connect digital payment with bank")
    }
    else {
      history.push("/fleet-card-config")
    }
  }


  const history = useHistory()
  const { isShowing, toggle } = UseModal();
  const [inputValues, setInputValues] = useState({});

  const [selected_options, setSelectedOptions] = useState([])
  const [selected, setSelected] = useState({});
  const [errors, setErrors] = useState({
    bank_name: "",
    short_name: "",
    account_type: "",
    ac_type_name: "",
    address: "",
    branch: "",
    account_number: "",
    phone: "",
    email: "",
    digital_payment_type: "",

  })
  const handleInputs = (e) => {
 
    if (e.target.value == 1) {
      setSelected({
        ...selected,
        [e.target.name]: "Current Account"
      })
    }
    else {
      setSelected({
        ...selected,
        [e.target.name]: "Savings Account"
      })
    }

    
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

  };

  const [search, setSearch] = useState('')
  const handleSearch = (event) => {
    setSearch(event.target.value)
  }
  const handleSelect = (items) => {

    var digital_payment_type = []

    items && items.map((item) => {
      digital_payment_type.push(item)
    })

    setInputValues({
      ...inputValues,
      digital_payment_type: digital_payment_type.map(payment => payment.value)
    });

    setSelectedOptions(digital_payment_type);

  };


  useEffect(() => {
    setLocation(location.location.pathname)
    check_url()

  }, []);


  //------------------------url
  function check_url() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        setStartoperation(responseData.stat_operation)

        if (responseData.allowurl == 1) {
          if (location.location.pathname == "/bank-opening/bank-opening") {
            history.push(location.location.pathname);
          }
          else {
            history.push(responseData.url);
          }
        
          ListBank(1);
        }
        else {

          history.goBack();
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }

  const [pages, setpages] = useState(
    {
      current_page: 1,
      last_page: 0,
      total_pages: 0,
      pagination_item: [],
      total_pages: 0,
    })

  const handlePagination = (number) => {
    setpages({
      ...pages,
      current_page: number
    }
    )

    ListBank(number)

  }
  //loader
  const [Loading, setLoading] = useState(false);
  function ListBank(page) {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };

    fetch(`${API_URL}/bank?search_keyword=${search}&page=${page}`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        if (responseData.status == 3) {
          setPermission({ ...permission, status: responseData.status, message: responseData.message })
        }
        // responseData.bank_details.data.map((elem)=>{
        //   elem.get_payment_methods((elm1)=>{
        //     setGetPayment(elem1)
        //   })
        // })

        setList(responseData.bank_details.data)
        ckeckOption({
          ...checkoption,
          isdayclose: responseData.isdayclose
        })
        setCheck(responseData.check === true ? true : false,)
       

        
        setpages({
          ...pages,
          current_page: responseData.bank_details.current_page,
          last_page: responseData.bank_details.last_page,
          per_pages:  responseData.bank_details.per_page,
          total_pages:responseData.bank_details.total,
           
          })
     
        setLoading(true);
        return responseData;

      })
      .catch(e => {
        console.log(e);

      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setDouble(false);
    if (validateForm())
      sendBankData()

  }
  function validateForm() {
    let errors = {}
    let formIsValid = true
    console.log(inputValues)
    if (!inputValues.bank_name) {
      formIsValid = false;
      setDouble(true)
      errors["bank_name"] = "Enter Bank Name"
    }
    else {
      errors["bank_name"] = ""
    }
    if (!inputValues.short_name) {
      formIsValid = false;
      setDouble(true)
      errors["short_name"] = "Enter Short Name"
    }
    else {
      errors["short_name"] = ""
    }

    if (!inputValues.account_type) {
      formIsValid = false;
      setDouble(true)
      errors["account_type"] = "Enter Account Type"
    }
    else {
      errors["account_type"] = ""
    }
    if (!inputValues.ac_type_name) {
      formIsValid = false;
      setDouble(true)
      errors["ac_type_name"] = "Enter Account Name"
    }
    else {
      errors["ac_type_name"] = ""
    }  
    if (inputValues["phone"] ) 
    {
     
      if (inputValues["phone"].length != 10) {
        formIsValid = false;
        setDouble(true);
        errors["phone"] = "Enter Valid Phone Number";

     }
    }
    if (inputValues["email"]) 
    {
    if (typeof inputValues["email"] !== "undefined") {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(inputValues["email"])) {
        formIsValid = false;
        setDouble(true)
        errors["email"] = "Enter valid Email Id"
      }
    }
  }

  if(stat_operation!="Yes"){
    if (inputValues.opening_balance != undefined) {

      if ((inputValues.opening_balance != "") && (inputValues.debit_credit == undefined || inputValues.debit_credit === "")) {
        formIsValid = false;
        setDouble(true)
        errors["debit_credit"] = " Select Debit/Credit"
      }
    }

    if (!inputValues.opening_balance) {
      setDouble(true)
      formIsValid = false;
      errors["opening_balance"] = "Enter Opening Balance"
    }
    
    // if (inputValues.debit_credit == undefined) {
    //   setDouble(true)
    //   formIsValid = false;
    //   errors["debit_credit"] = "Enter Opening Balance"
    // }
    
    }



    if (inputValues.credit_amount === 1 && !inputValues.credit_limit) {
      setDouble(true)
      formIsValid = false;
      errors["credit_limit"] = " Enter Credit Amount"


    }


    if ((inputValues.credit_period === 1) && (!inputValues.credit_limit_period)) {
      setDouble(true)
      formIsValid = false;
      errors["credit_limit_period"] = " Enter Credit Limit period"


    }
    if ((inputValues.credit_limit_period == "no_of_days" && !inputValues.credit_days)) {
      setDouble(true)
      formIsValid = false;
      errors["credit_days"] = " Enter Credit Days"
    }
    if (!inputValues.account_number) {
      
      formIsValid = false;
      setDouble(true)
      errors["account_number"] = "Enter Account Number";
    }
    if (typeof inputValues["account_number"] !== "undefined" || inputValues.account_number) {
       if (inputValues["account_number"].length < 9) {
        formIsValid = false;
        setDouble(true)
        errors["account_number"] = "Invalid Account Number";
       }
       if (inputValues["account_number"].length > 18) {
        formIsValid = false;
        setDouble(true)
        errors["account_number"] = "Invalid Account Number";
       }
      
     
      
      
      

    
  }
    setErrors(errors)

    return formIsValid;


  }
  
  async function sendBankData() {
   
    const requestOptions = {
      method: 'POST',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify(inputValues)
    };
    await fetch(API_URL + "/bank", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(responseData => {
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        })
        let errors = []
        if (responseData.status == false) {
          errors["bank_name"] = responseData.response.bank_name
          errors["account_number"] = responseData.response.account_number
          errors["digital_payment_type"] = responseData.response.digital_payment_type
          errors["ac_type_name"] = responseData.response.ac_type_name
          errors["opening_balance"] = responseData.response.opening_balance
          errors["short_name"] = responseData.response.short_name
          setDouble(true);
        }
        else if (responseData.status == true) {
          swal("", responseData.message, responseData.status ? "success" : "warning")
          setDouble(true)
          setErrors({})
          toggle();
          ListBank(pages.current_page);
        }
        setErrors(errors)
        return responseData;
      }).catch(e => {
        swal("Something went wrong", "", "warning")
        console.log(e)
        setDouble(true)
        console.log(e);
      });
  }
  const [path, setLocation] = useState([])
  const [dropdownData, setDropdownData] = useState([])
  const [success, setSuccess] = useState([])
  const [check, setCheck] = useState()
  const [list_data, setList] = useState([])
  const [checkoption, ckeckOption] = useState([])

  async function edit_product(id, ac_name) {


    var isdayclose = checkoption.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {
      const requestOptions = {
        method: 'get',
        data: {
          id: id,
          ac_name: ac_name
        },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"

        },
      };
      await fetch(API_URL + "/bank/" + id + "/edit", requestOptions)
        .then(response => { return response.json(); })
        .then(responseData => {

          var id_itr = 0
          var digital = []
          if (responseData[0].PaymentMethods) {
            responseData[0].PaymentMethods.split(',').forEach((PaymentMethods) => {
              digital.push({
                value: responseData[0].payment_id[id_itr],
                label: PaymentMethods,
              })
              id_itr++
            })
            setSelectedOptions(digital)
          }

          // setInputValues(responseData[0])
          setInputValues({
            id: responseData[0].id ? responseData[0].id : "",
            ac_name: responseData[0].ac_name ? responseData[0].ac_name : "",
            bank_name: responseData[0].bank_name,
            short_name: responseData[0].short_name,
            account_type: responseData[0].account_type,
            ac_type_name: responseData[0].ac_type_name,
            address: responseData[0].address,
            branch: responseData[0].branch,
            phone: responseData[0].phone,
            email: responseData[0].email,
            account_number: responseData[0].account_number,
            opening_balance: responseData[0].opening_balance,
            digital_payment_type: responseData[0].payment_id,
            debit_credit:responseData[0].debit_credit,
            credit_limit:responseData[0].credit_limit,
            
            credit_days:responseData[0].credit_days,
            credit_amount: responseData[0].credit_limit === null ? 0 : 1,
            credit_period: responseData[0].credit_limit_period === null ? 0 : 1,
            credit_days: responseData[0].credit_limit_period,
            credit_limit_period:
            isNaN(parseInt(responseData[0].credit_limit_period)) == false
              ? "no_of_days"
              : responseData[0].credit_limit_period,

            ob_is_dayclose: responseData[0].ob_is_dayclose == 1 ? true : false,

          });
          // }) 
          toggle();
          ListBank(pages.current_page);


          return responseData;
        })
        .catch(e => {
          console.log(e);

        });
    }
  }
  async function getDigitalPayment() {
    const requestOptions = {
      method: 'get',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    await fetch(API_URL + "/payment-methods", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        var datas = []
        // console.log(responseData)
        //     responseData.forEach(elem=>{
        //      console.log(elem.method_name);
        //      datas.push([elem.id,elem.method_name])
        //   })
        //   console.log(datas)
        //   setDropdownData(datas);
        responseData.data.forEach((elem) => {
          setDropdownData((dropdownData) => [
            ...dropdownData,
            {
              value: elem.id,
              label: elem.method_name,
            },
          ]);
        });
        return responseData;
      })
      .catch(e => {
        console.log(e);

      });
  }

  function delete_Bank(id) {
    var isdayclose = checkoption.isdayclose;
    if (isdayclose == 1) {
      swal("", "You do not have this permission after day close", false ? "success" : "warning")
    }
    else {
      deleteId(id)

      toggleModal()
    }
  }

  function deleteBank() {
    setDouble(false)
    // console.log(...delete_id)
    const requestOptions = {
      method: 'DELETE',

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
      body: JSON.stringify(delete_id)
    };
    fetch(API_URL + "/bank/" + delete_id, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        ListBank(pages.current_page);
        toggleModal()
        setDouble(true)
        return responseData;


      })

      .catch(err => {
        console.log(err)
        swal("Something went wrong", "", "warning")

      })
  }
  const handleIn = (e) => {

    if (e.target.name == "amount" || !inputValues.price) {
      var quantity = inputValues.price / e.target.value ? e.target.value / inputValues.price : null
    }



    preventNonNumericalInput(e,e.target.value)
    if (e.target.value == 1) {
      setSelected({
        ...selected,
        [e.target.name]: "Current Account"
      })
    }
    else {
      setSelected({
        ...selected,
        [e.target.name]: "Savings Account"
      })
    }
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });

    convertAmountToWords(e, e.target.value)

  }

  return (
    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          {path == "/bank-opening/bank-opening" ? <Sidebar_Progress path={path} /> : <Sidebar_Progress />}
          <div className="content-area">
            <LoginHeader page_title="" />

            <div className="container pb-3  mt-3">
              <div className="row">

                {path == "/bank-opening/bank-opening" ? <div className="col-md-12 mb-3 px-4 "><Link to={backPage || "/operational-date"}>
                  <a href="#" className="float-end btn btn-gray">Go Back</a>
                </Link></div> : <div className="col-md-12 mb-3 px-4 "><Link to="/digital-payment">
                  <a href="digital-payment" className="float-end btn btn-gray">Back</a>
                </Link></div>}
              </div>
              <div className="row justify-content-center  form-container">
                <div className="col-md-12">
                  {permission.status == 3 ?
                    <div className="form-wrap">
                      <div className="text-center">
                        {permission.message}
                      </div></div> :
                    <>
                      <div>
                        {Loading ? ListBank :
                          // <div class="loader text-primary">Loading...</div>
                          <Loader />
                        }
                        <div className="form-wrap list-wrp">
                          <div className="row mb-3">
                            <div className="col-md">
                              <div className="row">
                                <div className="col-md-1 col-2 pe-0">
                                  <img src={museum} className="img-fluid" alt=".." width="25px" />
                                </div>
                                <div className="col ps-0">
                                  <h3 className="table-title"> BANKS OPENING BALANCES</h3>

                                </div>
                                <div className="col-md">
                                  <a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#bank" onClick={toggle}>Add Banks</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="input-group mb-3 search">
                                <span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
                                </span>
                                <input type="text" className="form-control" placeholder="Search With Bank Name " value={search.search_keyword} onChange={handleSearch}  />
                                <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

                             </div>
                            </div>
                          </div>
                          <div className="table-widget">
                            <table className="table border">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Address</th>
                                  <th>Phone </th>
                                  <th>Email</th>
                                  <th>Branch</th>
                                  <th>Account Number</th>
                                  <th>Configured Opening Balance</th>
                                  <th>Digital Payment</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  list_data.map(bank => (
                                    <tr>
                                      <td>{bank.bank_name}  </td>
                                      <td> {bank.address}</td>
                                      <td>{bank.phone}</td>
                                      <td>{bank.email}</td>
                                      <td>{bank.branch}</td>
                                      <td>{bank.account_number}</td>
                                      <td className="text-end">{bank.opening_balance}</td>
                                      <td>{bank.PaymentMethods ? bank.PaymentMethods : ''}</td>
                                      <td><div className="d-flex icon-btn">
                                        <button onClick={() => edit_product(bank.id, bank.ac_name)} >  <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </button>
                                        <button data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete" onClick={() => delete_Bank(bank.id)}>  <i className="fa fa-trash" aria-hidden="true"></i>
                                        </button>
                                      </div></td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {Loading ?  <PaginationComp
										pages={pages}
										handlePagination={handlePagination}
										/>:""
										}
                          </div>

                        </div>

                        {path == "/bank-opening/bank-opening" ? "" : <div className="text-end mt-4"> <a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={setBank}> Skip/Continue</a></div>}
                      </div>
                    </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShowing ? (<BankModal
        isShowing={isShowing}
        hide={toggle}
        handleIn={handleIn}
        handleInputs={handleInputs}
        inputValues={inputValues}
        dropdownData={dropdownData}
        preventNonNumericalInput={preventNonNumericalInput}
        prevNonNumericalWithDec ={prevNonNumericalWithDec}
        handleSubmit={handleSubmit}
        double={double}
        handleSelect={handleSelect}
        selected_options={selected_options}
        errors={errors}
        success={success}
        selected={selected}
        stat_operation={stat_operation}

      />) : null
      }

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >
        <div class="modal-content">
          <div class="modal-title modal-header"><h5 class="modal-title" id="exampleModalLongTitle">BANKS OPENING BALANCES</h5><button type="button" onClick={toggleModal} class="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
          <div class=" modal-body  modal-body"><p>Are you sure you want to delete this OPENING BALANCES</p></div>
          <div class="justify-content-end  modal-footer"><div class="d-flex">
            <button type="button" class="btn btn-secondary me-2" onClick={toggleModal}>No</button>
           {double == true ? <button type="submit" class="btn btn-primary" onClick={deleteBank}>Yes</button>:<button type="submit" class="btn btn-primary" disabled onClick={deleteBank}>Yes</button>}
          </div>
          </div>
        </div>
      </Modal>

    </>
  )
}

export default BankOpeningBalance