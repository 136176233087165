import React, { useEffect } from 'react';
import { account_type } from "../../constant/constant"
import Select from 'react-select';
import ReactTooltip from 'react-tooltip' 

const BankConfigModal = ({ preventNonNumericalInput,prevNonNumericalWithDec,isShowing,double, selected_options,handleIn, hide,selected,handleSelect, errors,success, inputValues, dropdownData, handleSubmit, handleInputs,stat_operation }) => {
 
 
  useEffect(() => {
  
    let testData = dropdownData.filter(data => {
      return data[0] == inputValues["digital_payment_type"]
    });
   
  })
  return (
 <React.Fragment>

<div className="modal-overlay"/>
      <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
        <div className="modal modalxl">
          <div className="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Bank</h5>
            <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form>
          <div class="row mt-2">
            <div class="col-md-6">
         <div class="form-group mb-3">
          <label >Bank Full Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="bank_name" value={inputValues["bank_name"]} onChange={handleInputs} placeholder=""/>
         <div className="errorMsg">{errors.bank_name}</div>
       </div>
       <div class="form-group mb-3">
         <label>Bank Short Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="short_name" value={inputValues["short_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.short_name}</div>
       </div>
       <div class="form-group mb-3">
         <label>Account Type<sup class="star">*</sup></label>
         <div class="select-container">
           <select  class="form-control" name="account_type" value={inputValues.account_type} onChange={handleInputs}>
             <option value="">Account Type </option>
             <option value="1">Current Account</option>       
             <option value="2">Savings Account</option>
             </select>
             </div>
             <div className="errorMsg">{errors.account_type}</div>
       </div>
    
       { inputValues.account_type == 1 &&
       <div class="form-group mb-3">
         <label>Current Account Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="ac_type_name" value={inputValues["ac_type_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.ac_type_name}</div>
       </div>
}
   {/* { inputValues.account_type == 2 &&
       <div class="form-group mb-3">
         <label>Loan account name</label>
         <input type="text" class="form-control" name="ac_type_name" value={inputValues["ac_type_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.ac_type_name}</div>
       </div>
  } */}
   { inputValues.account_type == 2 &&
       <div class="form-group mb-3">
         <label>Savings Account Name<sup class="star">*</sup></label>
         <input type="text" class="form-control" name="ac_type_name" value={inputValues["ac_type_name"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.ac_type_name}</div> 
       </div>
   }
  
   { inputValues["ac_type_name"] ? inputValues["ac_type_name"] : '' } { inputValues["short_name"] ? inputValues["short_name"] : '' } { selected["account_type"] ? selected.account_type: '' }  
       <div class="form-group mb-3">
         <label>Address</label>
         <input type="text" class="form-control" name="address" value={inputValues["address"]} onChange={handleInputs} />
         <div className="errorMsg">{errors.address}</div>
       </div>

       {stat_operation != "Yes" ?
              <div className="form-group">
              
                <label>Opening Balance</label>
                <input
                  type="number"
                  onKeyPress={(event)=>preventNonNumericalInput(event,inputValues["opening_balance"])}
                  className="form-control hide-arrow"
                  placeholder="opening balance"
                  name="opening_balance"
                  id={"open"}
                  value={inputValues["opening_balance"]}
                  onChange={handleIn}
                  readOnly={inputValues["ob_is_dayclose"]}
                />
                <div className="errorMsg">{errors.opening_balance}</div>
                <span  class="amount-words"></span>
                {inputValues.opening_balance != null && (
                  <div className="errorMsg">{errors.debit_credit}</div>
                )}
              </div>
            
              :""}
              {stat_operation != "Yes" ?
              <div className='form-group'>
              <div className="form-group d-flex align-items-center">
  <div className="form-check me-3">
    <input
      className="form-check-input"
      type="radio"
      id="creditRadio"
      name="debit_credit"
      value="Cr"
      onChange={handleInputs}
      checked={inputValues.debit_credit === "Cr"}
    />
    <label className="form-check-label col-form-label" htmlFor="creditRadio">
      Credit
    </label>
  </div>

  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      id="debitRadio"
      name="debit_credit"
      value="Dr"
      onChange={handleInputs}
      checked={inputValues.debit_credit === "Dr"}
    />
    <label className="form-check-label col-form-label" htmlFor="debitRadio">
      Debit
    </label>
  </div>
</div>


              </div> :""}
            

  </div>
  
       <div class="col-md-6 ">
                <div class="form-group mb-3 ">
                  <label>Branch</label>
         <input type="text" class="form-control"  name="branch" value={inputValues["branch"]} onChange={handleInputs}/>
         <div className="errorMsg">{errors.branch}</div>
       </div>
       <div class="form-group mb-3">
         <label>Account Number<sup class="star">*</sup></label>
         <input type="number" class="form-control hide-arrow ph_no" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}  name="account_number" id={"account"} value={inputValues["account_number"]} onChange={handleInputs}/>
         <div className="errorMsg">{errors.account_number}</div>
       </div>
       <div class="form-group mb-3">
         <label>Phone</label>
         <input type="number" class="form-control hide-arrow ph_no" onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)} name="phone"  id={"phone"} value={inputValues["phone"]} onChange={handleInputs} />
         
                    <div className="errorMsg">{errors.phone}</div> 
               
         {console.log(inputValues,"hhhhhhhhhhhhh")}
       </div>
       <div class="form-group mb-3">
         <label>Email</label>
         <input type="email" class="form-control"  name="email" value={inputValues["email"]}  onChange={handleInputs}  />
         
                    <div className="errorMsg">{errors.email}</div> 
               
       </div>

       {/* { (inputValues.account_type == 1  ||  inputValues.account_type == 3) && */}
       <div class="form-group mb-3">
         <label>Digital Payment Type </label>
        
         <Select   
     isMulti
       value={selected_options}
       onChange={handleSelect}
       options={dropdownData}
      > 
      </Select>
            <p className="note mt-2">Note: Select digital payment type linked with bank account</p>
            <div className="errorMsg">{errors.digital_payment_type}</div>
      
          
       </div>
       {stat_operation != "Yes" ?
       <div className="row mt-3">
  <div className="col-md-5">
    <div className="form-group row mx-0">
      <div className="form-check col-sm-12 pr-0">
        <input
          className="form-check-input"
          type="checkbox"
          name="credit_amount"
          checked={inputValues.credit_amount === 1}
          onChange={(e) =>
            handleInputs({
              target: { name: "credit_amount", value: e.target.checked ? 1 : 0 },
            })
          }
        />
        <label className="form-check-label col-form-label"> Credit Limit Amount </label>
      </div>
    </div>

    {inputValues.credit_amount === 1 && (
      <div className="col-sm-12 px-0">
        <div className="form-group">
          <input
            type="number"
            onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
            className="form-control hide-arrow"
            name="credit_limit"
            value={inputValues.credit_limit || ""}
            onChange={handleInputs}
          />
          {!inputValues.credit_limit && <div className="errorMsg">{errors.credit_limit}</div>}
        </div>
      </div>
    )}
  </div>

  <div className="col-md-7">
    <div className="form-group row mx-0">
      <div className="form-check col-sm-12 pr-0">
        <input
          className="form-check-input"
          type="checkbox"
          name="credit_period"
          checked={inputValues.credit_period === 1}
          onChange={(e) =>
            handleInputs({
              target: { name: "credit_period", value: e.target.checked ? 1 : 0 },
            })
          }
        />
        <label className="form-check-label col-form-label"> Credit Period </label>
      </div>

      {/* {inputValues.credit_period === 1 && (
        <div className="col-sm-8 px-0">
          <div className="select-container">
            <select
              className="form-control"
              name="credit_limit_period"
              value={inputValues.credit_limit_period || ""}
              onChange={handleInputs}
            >
              <option value="">Select</option>
              <option value="no_of_days">Number of days</option>
              <option value="one_time">One time</option>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
              <option value="half_of_month">Half of month</option>
            </select>
          </div>
          {!inputValues.credit_limit_period && <div className="errorMsg">{errors.credit_limit_period}</div>}
        </div>
      )}

      {inputValues.credit_limit_period === "no_of_days" && (
         <div className="col-sm-12 px-0">
        <div className="col-sm-4">
          <div className="form-group">
            <input
              type="number"
              onKeyPress={(event) => prevNonNumericalWithDec(event, event.target.value)}
              className="form-control"
              placeholder="Days"
              name="credit_days"
              min={1}
              max={100}
              value={inputValues.credit_days || ""}
              onChange={handleInputs}
            />
          </div>
          {!inputValues.credit_days && <div className="errorMsg">{errors.credit_days}</div>}
        </div>
        </div>
      )} */}
       {inputValues.credit_period == 1 ? (
                  <div className="col-sm-8 px-0 ">
                    <div className="select-container">
                      <select
                        id="Reliance"
                        className="form-control"
                        name="credit_limit_period"
                        value={inputValues["credit_limit_period"]}
                        onChange={handleInputs}
                      >
                        <option value="select">Select</option>

                        {inputValues.credit_limit_period === "no_of_days" ? (
                          <option value="no_of_days" selected>
                            {" "}
                            Number of days
                          </option>
                        ) : (
                            <option value="no_of_days"> Number of days</option>
                          )}

                        <option value="one_time">One time</option>
                        <option value="monthly">Monthly</option>
                        <option value="weekly">Weekly</option>
                        <option value="half_of_month">Half of month</option>
                      </select>
                    </div>
                    {(inputValues.credit_period ==1 && !inputValues.credit_limit_period) ? <div className="errorMsg">{errors.credit_limit_period}</div>:errors.credit_limit_period=""}            
                  </div>
                  
                ) :inputValues.credit_limit_period=""}
                <div className="col-sm-6">
                  {inputValues.credit_limit_period == "no_of_days" ? (
                    <div className="form-group">
                      <input
                        type="number"
                        onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}
                        className="form-control"
                        placeholder="Days"
                        name="credit_days"
                        min={1}
                        max={100}
                        value={inputValues["credit_days"]}
                        onChange={handleInputs}
                      />
                    </div>
                  ): inputValues.credit_days=""}
                 {(inputValues.credit_limit_period=="no_of_days" && !inputValues.credit_days)?<div className="errorMsg">{errors.credit_days}</div>:errors.credit_days=""}    
                </div>
    
    </div>
  </div>
</div>:""}

{/* } */}
{/* {  inputValues.account_type == 3 &&
       <div class="form-group mb-3">
         <label>Digital payment Type </label>
        <div class="select-container">
          <select id="3" class="form-control"  name="digital_payment_type" value={inputValues["digital_payment_type"]} onChange={handleInputs}>
            <option value="">Digital payment Type</option>
            {dropdownData.map((data)=>{
               return <option value={data[0]}>{data[1]}</option>
              })
            }
            </select></div>
            <div className="errorMsg">{errors.digital_payment_type}</div>
       </div>
}  */}
  
             </div> 

             
           

            </div>
            <div class="modal-footer justify-content-center">
           {/* <button type="submit" class="btn btn-primary" onClick={handleSubmit}>Save Bank</button> */}
           {double===true ?  <button type="submit" class="btn btn-primary"  onClick={handleSubmit}>Save Bank</button> : <button type="submit" class="btn btn-primary" disabled>Save Bank</button>}
        </div>
  </form>
        </div>
      </div>


</React.Fragment>
    );
  }
       export default BankConfigModal;